// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.blogDescription').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
    InitBannerSlider();
    jQuery.fn.sortDivs = function sortDivs() {
        $('> .move-section-home', this[0]).sort(dec_sort).appendTo(this[0]);
        function dec_sort(a, b) { return ($(b).data('order')) < ($(a).data('order')) ? 1 : -1; }
    }
    $('.sortablePage').sortDivs();
    if ($('.successmessage').is(":visible")) {
        $('#wf-form-Secure-Login-Form #pass1').hide();
        $('#wf-form-Secure-Login-Form #pass2').hide();
        $('#wf-form-Secure-Login-Form #set-pass-btn').hide();
    }
    var cookieSettings = new BootstrapCookieConsentSettings({
        cookiePolicyUrl: "/cookie-policy",
        contentURL: "/Scripts/cookie-consent-content", // this folder must contain the language-files in the needed languages (`[lang].js`)
        buttonAgreeClass: "pf-btn pf-btn-primary", // the "Agree to all" buttons class
        buttonDontAgreeClass: "pf-btn pf-btn-secondary", // the "I do not agree" buttons class
        buttonSaveClass: "pf-btn pf-btn-primary", // the "Save selection" buttons class
        buttonMySettingsClass: "pf-btn pf-btn-secondary",
        autoShowModal: true, // disable autoShowModal on the privacy policy and legal notice pages, to make these pages readable
        postSelectionCallback: function () {
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag("consent", "update", {
                ad_storage: ((cookieSettings.getSettings("ad_storage") === true) ? "granted" : "denied"),
                analytics_storage: ((cookieSettings.getSettings("analytics_storage") === true) ? "granted" : "denied"),
                functionality_storage: ((cookieSettings.getSettings("functionality_storage") === true) ? "granted" : "denied"),
                personalization_storage: ((cookieSettings.getSettings("personalization_storage") === true) ? "granted" : "denied"),
                security_storage: ((cookieSettings.getSettings("security_storage") === true) ? "granted" : "denied")
            });
            
            $.ajax({
                url: root + 'SiteSettings/GetAnalyticsCode',
                type: 'post',
                traditional: true,
                success: function (returnData)
                {
                    $('#AnalyticsCode').append(returnData);
                }
            });

        }, // callback function, called after the user has saved the settings
        lang: "en", // the language, in which the modal is shown
        defaultLang: "en", // default language, if `lang` is not available as translation in `cookie-consent-content`
        categories: ["necessary","ad_storage", "analytics_storage", "functionality_storage"], // the categories for selection, must be contained in the language files
        cookieName: "cookie-consent-settings",  // the name of the cookie in which the configuration is stored as JSON
        cookieStorageDays: 365, // the duration the cookie configuration is stored on the client
        modalId: "bootstrapCookieConsentSettingsModal" // the id of the modal dialog element
    });
});
$(function () {

 

    $(window).on('resize', SetNavDropdownMaxHeight);
    $(document).on('click', '.menu-button', SetNavDropdownMaxHeight);
    $(document).on('click', '.nav-link.nav-dropdown', SetNavDropdownMaxHeight);
    $(document).on('click', '.bag-opener.w-nav-link', SetNavDropdownMaxHeight);
    $('#bag-opener').click(function () {
        SetNavDropdownMaxHeight();
    });
    $(window).on('resize', InitBannerSlider);
    $(document).on("scroll", function () {
        if ($(document).scrollTop() > 100) {
            $("header").addClass("shrink");
        }
        else {
            $("header").removeClass("shrink");
        }
    });
    $('.desktop-search-icon').click(function () {
        var e = document.getElementById("search-form");
        if (e.style.display === 'block') {
            e.style.display = 'none';
        }
        else { 
            e.style.display = 'block';
        }
        $('#prod-search-tbx').focus();
    });
    
    $('.searchform-clear + input[type="search"]').on('input', function () {
        var $clearButton = $(this).prev('.searchform-clear');
        if ($(this).val() !== '')
            $clearButton.show();
        else
            $clearButton.hide();
    });
    $('.searchform-clear').on('click', function () {
        $(this).next('input[type="search"]').val('');
        $(this).hide();
    });
    InitFeaturedProductSlider(true);
    $('.move-section-home').each(function () {
        $("<a href='#' class='move-btn-list-home' data-id='" + $(this).data('content-id') + "'><i class='fas fa-arrows'></i></a>").prependTo($(this));
    });
    var isAdmin = $('body').data('is-admin');
    if (isAdmin == "True") {
        $('.sortablePage').sortable({
            items: '.move-section-home',
            handle: '.move-btn-list-home',
            axis: 'y',
            helper: 'original',
            forcePlaceHolderSize: true,
            tolerance: "pointer",
            context: this,
            update: function () {
                var order = $(this).sortable('toArray', { attribute: 'data-element-id' });
                $.ajax({
                    url: root + 'Custom/SaveOrderHomePage',
                    data: { order: order },
                    type: 'post',
                    traditional: true
                });
            }
        });
    }

    $('.pf-multi-level-sub-nav a.pf-multi-level-dropdown').on('click', function (e) {
        if (!$(this).hasClass('open')) {
            $(this).parents('.w-dropdown').first().find('.open').removeClass('open');
        }
        $(this).toggleClass('open');
    });

    $('#sidenav ul > li ul > li > span').on('click', function () {
        var $ul = $(this).closest('ul');
        var $li = $(this).closest('li');
        var $ol = $(this).next('ol');
        if (!$li.hasClass('open')) {
            $ul.find('.open').removeClass('open');
        }
        if (!$ol.hasClass('show')) {
            $ul.find('.show').removeClass('show');
        }
        $li.toggleClass('open');
        $ol.toggleClass('show');
    });
    $('#sidenav .sidenav-close').on('click', function () {
        mobileDrawer.close();
    });
    $(document).on("click", ".deliveryOptionRbn", function (e) {
        CheckStockItemCarts()
        return false;
    });
    $(document).on('itemUpdated.cart.platform', function (event, data) {
        CheckStockItemCarts()
    });

    $(document).on('itemRemoved.cart.platform', function (event, data) {
        CheckStockItemCarts()
    });

    $(document).on('discountUpdated.cart.platform', function (event, data) {
        CheckStockItemCarts()
    });
    $('.nav-menu.w-nav-menu .w-dropdown').hover(function (e) {
        e.preventDefault();
        if ($(window).width() < 768)
            return;

        var openClass = 'w--open';
        if (e.type === 'mouseenter') {
            $(this).find('.w-dropdown-toggle').addClass(openClass);
            $(this).find('.w-dropdown-list').addClass(openClass);
            $(this).find('.nav-dropdown-strip').addClass(openClass);
        } else if (e.type === 'mouseleave') {
            $(this).find('.w-dropdown-toggle').removeClass(openClass);
            $(this).find('.w-dropdown-list').removeClass(openClass);
            $(this).find('.nav-dropdown-strip').removeClass(openClass);
        }
        SetNavDropdownMaxHeight();
    });
    var enquireModal = $('[data-remodal-id=enquire-modal]').remodal();
    $(document).on('click', '.pf-enquire-product', function () {
        //Product List
        if ($(this).parent('.pf-list-view-enquire-wrapper').length) {
            var prodInfoWrapper = $(this).parent().parent().parent();
            var name = prodInfoWrapper.find('.pf-product-name').text();
            var code = prodInfoWrapper.find('.pf-product-code').text();
            code = code.replace('Item Code: ', '');
            code = code.split('Part Number')[0];
            $('#prodname').val(name);
            $('#prodcode').val(code);
        }
        //Variant
        if ($(this).closest('.pf-product-variant-item').length) {
            var prodInfoWrapper = $(this).closest('.pf-product-variant-item');
            var name = prodInfoWrapper.children('[itemprop="name"]').attr('content');
            var code = prodInfoWrapper.find('[itemprop="sku"]').attr('content');
            $('#prodname').val(name);
            $('#prodcode').val(code);
        }
        //Quick Order
        if ($(this).hasClass('pf-enquire-product-quickorder')) {
            var name = $(this).data("name");
            var code = $(this).data("code");
            $('#prodname').val(name);
            $('#prodcode').val(code);
        }
        enquireModal.open();
    });
});
function CheckStockItemCarts()
{
    var deliveryOptionsIds = ['51', '56', '61', '66', '71', '76', '81', '86', '91', '96'];
    var deliveryOptionId = $('input[name=deliveryOption]:radio:checked').val();
    if (deliveryOptionsIds.includes(deliveryOptionId)) {
        $.post(root + "Custom/AnyCartItemOutOfStock", function (data) {
            if (data == "True") {
                $('.custom-express-message .checkbox-field').css('display', 'block');
            }
            else {
                $('.custom-express-message .checkbox-field').css('display', 'none');
            }

        });
    }
    else
    {
        $('.custom-express-message .checkbox-field').css('display', 'none');
    }
}
function SetNavDropdownMaxHeight() {
    if ($(window).width() < 768) {
        $('.w-nav-overlay').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.mega-list').css('max-height', '100%');
    } else {
        $('.mega-list').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', '400px');
        $('.topheader').css('overflow', 'auto');
        $('.w-nav-overlay').css('max-height', '100%');
    }
}

function InitBannerSlider() {
    var $visibleBanner = $('.w-slider:visible');
    if (!$visibleBanner.data('initialised')) {
        Webflow.require('slider').redraw();
        $visibleBanner.data('initialised', true);
    }
}
function InitFeaturedProductSlider(reinitialize) {
    $('.featured-products').each(function () {
        var $slider = $(this);
        /* Initializes a slick carousel only on tablet and mobile screens */
        // slick on tablet
        if ($(window).outerWidth() > 768) {
            if ($slider.hasClass('slick-initialized')) {
                $slider.slick('unslick');
            }
        }
        if ($(window).outerWidth() <= 768) {
            if (reinitialize && $slider.hasClass('slick-initialized')) {
                $slider.slick('unslick');
            }
            if (reinitialize || !$slider.hasClass('slick-initialized')) {
                $('.featured-products').slick({
                    slidesToShow: 2,
                    rows: 0,
                    prevArrow: "<div class=\"pf-slider-button pf-slider-button-prev\"><i class=\"fas fa-chevron-left\"></i></div>",
                    nextArrow: "<div class=\"pf-slider-button pf-slider-button-next\"><i class=\"fas fa-chevron-right\"></i></div>",
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 450,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            }
        }
    });
    $('.product-match-height.pf-product-list-item .pf-product-name').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
}
//To be turned on if useful: 


////// compact header when opening and closing the expanded search functionality. 
//$('.search-opener').click(function () {
//    $(window).scrollTop(0);
//    $('#search-div-block-compact').show();
//    $('.logo-section').hide();
//});
//$('.search-close').click(function () {
//    if (document.body.scrollTop < 50 || document.documentElement.scrollTop < 50) {
//        $('#search-div-block-compact').hide();
//        $('.logo-section').show();
//    }
//});


///// Hide promotional strip when using mobile nav. 
//$('.hamburger-menu-button').click(function () {
//    console.log($(this).is('.w--open'));
//    if ($(this).is('.w--open')) {
//        $('.pf-promotion-container').hide()
//    } else {
//        $('.pf-promotion-container').show()
//    }
//});

////// CoachHouse's compact header functionality
//window.onscroll = function () { scrollFunction() };
//function scrollFunction() {
//    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
//        $('#search-div-block-compact').show();
//        $('.logo-section').hide();
//    } else {
//        if (!$('.search-section').is(':visible')) {
//            $('#search-div-block-compact').hide();
//            $('.logo-section').show();
//        }
//    }
//}


/////// additional mac/ios specific styling required. add the class mac-os to the required element
//if (navigator.userAgent.indexOf('Mac') > 0) {
//    $('.pf-new-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//    $('.pf-sale-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//}